import React, { useState, useEffect } from 'react'
import axios from 'axios'
import ConfirmationDialog from './ConfirmationDialog'

import '../css/UserManagement.css'
import { FaClipboardList } from 'react-icons/fa'
import { BsTrash } from 'react-icons/bs'

const UserManagement = ({ onBack }) => {
  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
  const [confirmArchive, setConfirmArchive] = useState(false)
  const [newUser, setNewUser] = useState({
    username: '',
    password: '',
    role: '',
    name: '',
    region: '',
  })
  const [editUser, setEditUser] = useState({
    id: '',
    username: '',
    password: '',
    role: '',
    name: '',
    region: '',
  })
  const [regions, setRegions] = useState([])
  const [error, setError] = useState('')

  useEffect(() => {
    fetchUsers()
    fetchRegions()
  }, [])

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users`)
      setUsers(response.data)
    } catch (error) {
      console.error('Error fetching users:', error)
    }
  }

  const fetchRegions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/regions`
      )
      setRegions(response.data)
    } catch (error) {
      console.error('Error fetching regions:', error)
    }
  }

  const handleArchiveUser = async () => {
    if (!confirmArchive) {
      setConfirmArchive(true) // Ustawienie stanu, aby pokazać okno potwierdzenia
      return // Przerwanie funkcji, aby użytkownik potwierdził archiwizację
    }

    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/users/archive/${selectedUser.id}`
      )

      const userOrders = await axios.get(
        `${process.env.REACT_APP_API_URL}/orders/user/${selectedUser.id}`
      )

      for (const order of userOrders.data) {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/orders/archive/${order.id}`
        )
      }

      fetchUsers()
      setSelectedUser(null)
      setConfirmArchive(false) // Resetowanie stanu potwierdzenia
    } catch (error) {
      console.error('Error archiving user and orders:', error)
    }
  }

  const handleCancelArchive = () => {
    setConfirmArchive(false) // Ustawienie stanu, aby schować okno potwierdzenia
  }
  const handleUserSelect = (user) => {
    if (user === 'Wybierz użytkownika...') {
      setSelectedUser(null)
      setEditUser({
        id: '',
        username: '',
        password: '',
        role: '',
        name: '',
        region: '',
      })
    } else {
      setSelectedUser(user)
      setEditUser({
        id: user.id,
        username: user.username,
        password: '',
        role: user.role,
        name: user.name,
        region: user.region,
      })
    }
  }

  const handleNewUserChange = (e) => {
    const { name, value } = e.target
    setNewUser({ ...newUser, [name]: value })
  }

  const handleEditUserChange = (e) => {
    const { name, value } = e.target
    setEditUser({ ...editUser, [name]: value })
  }

  const handleNewUserSave = async () => {
    try {
      // Sprawdzenie, czy wszystkie wymagane pola są uzupełnione
      if (
        !newUser.username ||
        !newUser.password ||
        !newUser.role ||
        !newUser.name ||
        !newUser.region
      ) {
        setError('Wszystkie wymagane pola muszą być uzupełnione.')
        setTimeout(() => setError(''), 3000) // Ukrycie komunikatu o błędzie po 3 sekundach
        return
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users`,
        newUser
      )
      fetchUsers()
      setNewUser({
        username: '',
        password: '',
        role: '',
        name: '',
        region: '',
      })
    } catch (error) {
      setError(error.response.data.message)
      setTimeout(() => setError(''), 3000) // Ukrycie komunikatu o błędzie po 3 sekundach
    }
  }

  const handleEditUserSave = async () => {
    try {
      const { id, username, role, name, region } = editUser

      // Sprawdzenie, czy wszystkie wymagane pola są uzupełnione
      if (!username || !role || !name || !region) {
        setError('Wszystkie wymagane pola muszą być uzupełnione.')
        setTimeout(() => setError(''), 3000) // Ukrycie komunikatu o błędzie po 3 sekundach
        return
      }

      let updatedUserData = { username, role, name, region }

      if (editUser.password.trim() !== '') {
        updatedUserData.password = editUser.password
      }

      await axios.put(
        `${process.env.REACT_APP_API_URL}/users/${id}`,
        updatedUserData
      )

      fetchUsers()
      setSelectedUser(null)
      setEditUser({
        id: '',
        username: '',
        password: '',
        role: '',
        name: '',
        region: '',
      })
    } catch (error) {
      console.error('Error saving user:', error)
    }
  }

  const handleCancelEdit = () => {
    setSelectedUser(null) // Anulowanie edycji - wyczyszczenie wyboru użytkownika
    setEditUser({
      id: '',
      username: '',
      password: '',
      role: '',
      name: '',
      region: '',
    })
  }

  return (
    <div className='user-management section-padding'>
      <ConfirmationDialog
        isOpen={confirmArchive}
        message={
          <div>
            <p>
              Czy na pewno chcesz zarchiwizować tego użytkownika?
              <br />
              <br />
              Użytkownik zostanie zarchiwizowany razem ze wszystkimi powiązanymi
              zamówieniami.
            </p>
          </div>
        }
        onCancel={handleCancelArchive}
        onConfirm={handleArchiveUser}
      />

      <button className='onBack' onClick={onBack}>
        <FaClipboardList />
      </button>
      <h2>Zarządzanie użytkownikami</h2>

      <div className='user-form'>
        <h3>Dodaj nowego użytkownika</h3>
        <label>
          Nazwa użytkownika:
          <input
            type='text'
            name='username'
            value={newUser.username}
            autoComplete='off'
            onChange={handleNewUserChange}
          />
        </label>
        <label>
          Hasło:
          <input
            type='password'
            name='password'
            value={newUser.password}
            autoComplete='off'
            onChange={handleNewUserChange}
          />
        </label>
        <label>
          Rola:
          <select
            name='role'
            value={newUser.role}
            onChange={handleNewUserChange}
          >
            <option value=''>Wybierz rolę</option>
            <option value='admin'>Admin</option>
            <option value='user'>User</option>
          </select>
        </label>
        <label>
          Imię i Nazwisko:
          <input
            type='text'
            name='name'
            value={newUser.name}
            onChange={handleNewUserChange}
          />
        </label>
        <label>
          Region:
          <select
            name='region'
            value={newUser.region}
            onChange={handleNewUserChange}
          >
            <option value=''>Wybierz region</option>
            {regions.map((region) => (
              <option key={region.id} value={region.id}>
                {region.name}
              </option>
            ))}
          </select>
        </label>
        <div className='buttons'>
          <button className='button' onClick={handleNewUserSave}>
            Dodaj użytkownika
          </button>
        </div>
      </div>
      <div className='user-list'>
        <h3>Lista użytkowników</h3>
        <div className='user-scroll-list'>
          <ul>
            {users
              .filter((user) => !user.archived)
              .map((user) => (
                <li key={user.id} onClick={() => handleUserSelect(user)}>
                  {user.name} ({user.role})
                </li>
              ))}
          </ul>
        </div>
        {error && <p className='user-error'>{error}</p>}
      </div>

      {selectedUser && (
        <div className='edit-user-form'>
          <h3>
            Edytuj użytkownika:{' '}
            <span className='title'>{selectedUser.username}</span>
          </h3>

          <button
            className='cancel-btn archive button'
            onClick={handleArchiveUser}
          >
            <BsTrash />
          </button>

          <label>
            Nazwa użytkownika:
            <input
              type='text'
              name='username'
              value={editUser.username}
              onChange={handleEditUserChange}
            />
          </label>
          <label>
            Hasło:
            <input
              type='password'
              name='password'
              value={editUser.password}
              onChange={handleEditUserChange}
            />
          </label>
          <label>
            Rola:
            <select
              name='role'
              value={editUser.role}
              onChange={handleEditUserChange}
            >
              <option value=''>Wybierz rolę</option>
              <option value='admin'>Admin</option>
              <option value='user'>User</option>
            </select>
          </label>
          <label>
            Imię i Nazwisko:
            <input
              type='text'
              name='name'
              value={editUser.name}
              onChange={handleEditUserChange}
            />
          </label>
          <label>
            Region:
            <select
              name='region'
              value={editUser.region}
              onChange={handleEditUserChange}
            >
              <option value=''>Wybierz region</option>
              {regions.map((region) => (
                <option key={region.id} value={region.id}>
                  {region.name}
                </option>
              ))}
            </select>
          </label>
          <div className='buttons'>
            <button className='button' onClick={handleEditUserSave}>
              Zapisz zmiany
            </button>
            <button className='cancel-btn button' onClick={handleCancelEdit}>
              Anuluj
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default UserManagement
