import React, { useState, useEffect } from 'react'
import axios from 'axios'
import '../css/EditOrderForm.css'

const EditOrderForm = ({
  order,
  onEditOrderSuccess,
  fetchOrders,
  handleCancelEdit,
}) => {
  const [editedOrder, setEditedOrder] = useState({ ...order })
  const [error, setError] = useState('')
  const [users, setUsers] = useState([])
  const [companies, setCompanies] = useState([])
  const [regions, setRegions] = useState([])

  useEffect(() => {
    setEditedOrder({ ...order })
    fetchUsers()
    fetchCompanies()
    fetchRegions() // Moved this line to ensure regions are fetched on order change
  }, [order])

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users`)
      setUsers(response.data)
    } catch (error) {
      console.error('Error fetching users:', error)
    }
  }

  const fetchCompanies = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/companies`
      )
      setCompanies(response.data)
    } catch (error) {
      console.error('Error fetching companies:', error)
    }
  }

  const fetchRegions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/regions`
      )
      setRegions(response.data)

      // Set default region based on order's region_id
      const defaultRegion = response.data.find(
        (region) => region.id === order.region
      )
      if (defaultRegion) {
        setEditedOrder((prevOrder) => ({
          ...prevOrder,
          region: defaultRegion.id, // Ustawienie id regionu, a nie jego nazwy
        }))
      }
    } catch (error) {
      console.error('Error fetching regions:', error)
    }
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setEditedOrder({ ...editedOrder, [name]: value })
  }

  const validateForm = () => {
    if (
      !editedOrder.maszyna ||
      !editedOrder.model ||
      !editedOrder.klient ||
      !editedOrder.name ||
      !editedOrder.status ||
      !editedOrder.company ||
      !editedOrder.region
    ) {
      setError(
        'Proszę wypełnić wymagane pola: Maszyna, Model, Klient, Pracownik, Status, Firma, Region.'
      )
      return false
    }
    return true
  }

  const handleSaveEdit = async () => {
    setError('')

    if (!validateForm()) {
      return
    }

    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/orders/${editedOrder.id}`,
        editedOrder
      )

      onEditOrderSuccess(editedOrder) // Notify parent component about the edited order
      // Display success alert
      window.alert('Zamówienie zostało zaktualizowane!')
      fetchOrders()
    } catch (error) {
      console.error('Error saving order:', error)
    }
  }

  return (
    <div className='edit-order-form'>
      <h4>Edytuj zamówienie</h4>
      {error && <div className='edit-order-error'>{error}</div>}
      <input
        type='number'
        placeholder='Ile'
        name='ile'
        value={editedOrder.ile}
        onChange={handleInputChange}
      />
      <input
        type='text'
        placeholder='Maszyna'
        name='maszyna'
        value={editedOrder.maszyna}
        onChange={handleInputChange}
        required
      />
      <input
        type='text'
        placeholder='Model'
        name='model'
        value={editedOrder.model}
        onChange={handleInputChange}
        required
      />
      <input
        type='text'
        placeholder='Wersja'
        name='wersja'
        value={editedOrder.wersja}
        onChange={handleInputChange}
      />
      <textarea
        placeholder='Wyposażenie'
        name='wyposazenie'
        value={editedOrder.wyposazenie}
        onChange={handleInputChange}
      />
      <input
        type='text'
        placeholder='Nr fabryczny'
        name='nr_fabryczny'
        value={editedOrder.nr_fabryczny}
        onChange={handleInputChange}
      />
      <input
        type='text'
        placeholder='Nr zamówienia'
        name='nr_zam'
        value={editedOrder.nr_zam}
        onChange={handleInputChange}
      />
      <input
        type='text'
        placeholder='Klient'
        name='klient'
        value={editedOrder.klient}
        onChange={handleInputChange}
        required
      />
      <input
        type='text'
        placeholder='Czas realizacji'
        name='czasrealizacji'
        value={editedOrder.czasrealizacji}
        onChange={handleInputChange}
      />
      <input
        type='text'
        placeholder='Miejsce składowania'
        name='miejsce_skl'
        value={editedOrder.miejsce_skl}
        onChange={handleInputChange}
      />
      <input
        type='text'
        placeholder='Kwartał'
        name='kwartal'
        value={editedOrder.kwartal}
        onChange={handleInputChange}
      />
      <select name='name' value={editedOrder.name} onChange={handleInputChange}>
        {users
          .filter((user) => !user.archived) // Filtrujemy zarchiwizowanych użytkowników
          .map((user) => (
            <option key={user.id} value={user.id}>
              {user.name}
            </option>
          ))}
      </select>

      <select
        name='status'
        value={editedOrder.status}
        onChange={handleInputChange}
        required
      >
        <option value=''>Wybierz status</option>
        <option value='white'>Zamówiony</option>
        <option value='blue'>WZ</option>
        <option value='yellow'>Wymaga uwagi</option>
        <option value='green'>Czeka na placu</option>
      </select>
      <select
        name='company'
        value={editedOrder.company}
        onChange={handleInputChange}
        required
      >
        <option value=''>Wybierz firmę</option>
        {companies.map((company) => (
          <option key={company.id} value={company.id}>
            {company.companyname}
          </option>
        ))}
      </select>
      <select
        name='region'
        value={editedOrder.region}
        onChange={handleInputChange}
        required
      >
        <option value=''>Wybierz region</option>
        {regions.map((region) => (
          <option key={region.id} value={region.id}>
            {region.name}
          </option>
        ))}
      </select>

      <div className=''></div>
      <div className='buttons'>
        <button className='cancel-btn' onClick={handleCancelEdit}>
          Anuluj
        </button>
        <button className='save-btn' onClick={handleSaveEdit}>
          Zapisz
        </button>
      </div>
    </div>
  )
}

export default EditOrderForm
