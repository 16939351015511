import React, { useState } from 'react'
import axios from 'axios'
import logo from '../img/login.png' // Importuj logo
import '../css/Login.css'

const LoginForm = ({ onLogin }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/login`,
        {
          username,
          password,
        }
      )

      console.log(response.data)

      // Przekazanie danych zalogowanego użytkownika do funkcji onLogin
      onLogin(response.data.user)
    } catch (error) {
      console.error('Błąd logowania:', error.response.data.message)
      setErrorMessage(error.response.data.message)
      // Ustawienie timera na 5 sekund
      setTimeout(() => {
        setErrorMessage('')
      }, 5000)
    }
  }

  return (
    <div className='login-container'>
      <div className='login-form'>
        <div className='logo'>
          <img src={logo} alt='Logo' />
        </div>
        <form onSubmit={handleSubmit}>
          <label>
            Nazwa użytkownika:
            <input
              type='text'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </label>
          <label>
            Hasło:
            <input
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <button type='submit'>Zaloguj</button>
        </form>
        {errorMessage && <p className='error-message'>{errorMessage}</p>}
      </div>
    </div>
  )
}

export default LoginForm
