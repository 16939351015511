import React from 'react'
import logo from '../img/logo.png' // Importujemy obrazek (przyjmujemy, że masz logo.png w swoim projekcie)
import '../css/Footer.css'

const Footer = () => {
  return (
    <footer className='footer'>
      <a href='https://kosmokod.pl' target='_blank' rel='noopener noreferrer'>
        <img src={logo} alt='Logo' /> {/* Dodanie obrazka */}
      </a>

      <div className='footer-box'>
        <p>
          &copy; {new Date().getFullYear()} Professional Order Management Panel.{' '}
          All rights reserved.
        </p>
      </div>
    </footer>
  )
}

export default Footer
