import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { FaQuestion, FaCheck } from 'react-icons/fa'
import '../css/ConfirmationDialog.css'

const ConfirmationDialog = ({ isOpen, message, onCancel, onConfirm }) => {
  const [showSuccess, setShowSuccess] = useState(false)

  if (!isOpen && !showSuccess) return null

  const handleCancel = () => {
    onCancel()
  }

  const handleConfirm = () => {
    onConfirm()
    setShowSuccess(true)
    setTimeout(() => {
      setShowSuccess(false)
      onCancel() // lub inna metoda zamknięcia dialogu
    }, 600) // 3 sekundy
  }

  return ReactDOM.createPortal(
    <div className='confirmation-overlay'>
      <div className={`confirmation-dialog ${showSuccess ? 'success' : ''}`}>
        <div className='confirmation-icon-container'>
          {showSuccess ? (
            <FaCheck className='confirmation-icon success-icon' />
          ) : (
            <FaQuestion className='confirmation-icon' />
          )}
        </div>
        <p className='confirmation-message'>
          {showSuccess ? 'Operacja zakończona sukcesem!' : message}
        </p>
        {!showSuccess && (
          <div className='confirmation-buttons'>
            <button onClick={handleConfirm} className='confirm-button'>
              Potwierdź
            </button>
            <button className='cancel-button' onClick={handleCancel}>
              Anuluj
            </button>
          </div>
        )}
      </div>
    </div>,
    document.getElementById('portal-root')
  )
}

export default ConfirmationDialog
