import React, { useState, useEffect } from 'react'
import LoginForm from './components/Login'
import CompanyList from './components/CompanyList'
import UserManagement from './components/UserManagement'
import Footer from './components/footer'

import { IoWarningOutline } from 'react-icons/io5'

import icon from './img/logo.png' // Importuj logo
import './App.css'

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [user, setUser] = useState(null)
  const [currentDate, setCurrentDate] = useState('')
  const [licenseExpired, setLicenseExpired] = useState(false)
  const [userManagementVisible, setUserManagementVisible] = useState(false)

  const handleLogin = (userData) => {
    setIsLoggedIn(true)
    setUser(userData)
  }

  const handleLogout = () => {
    setIsLoggedIn(false)
    setUser(null)
    setUserManagementVisible(false)
  }

  const handleShowUserManagement = () => {
    setUserManagementVisible(true)
  }

  const handleBackToCompanyList = () => {
    setUserManagementVisible(false)
  }

  useEffect(() => {
    const getCurrentDate = () => {
      const date = new Date()
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }
      return date.toLocaleDateString('pl-PL', options)
    }

    setCurrentDate(getCurrentDate())

    console.log(
      '%cKosmo%cKod%c.pl',
      'color: orange; font-weight: bold; font-size: 42px',
      'color: purple; font-weight: bold; font-size: 42px',
      'color: white; font-weight: bold; font-size: 42px'
    )
    console.log(
      '%cSystem stworzony przez KosmoKod.pl',
      'font-size: 14px; font-weight: bold; color: white; padding: 5px;'
    )
    console.log(
      '%cSTOP!',
      'font-size: 20px; font-weight: bold; color: red; text-transform: uppercase; background-color: yellow; padding: 5px;'
    )
    console.log(
      '%cTa funkcja przeglądarki powinna służyć tylko twórcom systemu',
      'font-size: 14px; font-weight: bold; color: white; background-color: #333; padding: 5px;'
    )
    console.log(
      '%cNie wklejaj ani nie wpisuj tu poleceń podanych Ci przez inną osobę - możesz paść ofiarą oszustwa.',
      'font-size: 14px; font-weight: bold; color: white; background-color: #333; padding: 5px;'
    )
  }, [])
  if (licenseExpired) {
    return (
      <div className='App'>
        <div className='license-expired-box'>
          <div className='license-expired-container'>
            <div className='license-expired'>
              <span className='license-expired-icon'>
                <IoWarningOutline size={60} color='#e57373' />
              </span>
              <div className='license-expired-content'>
                <h2 className='license-expired-title'>Licencja wygasła</h2>
                <p className='license-expired-message'>
                  Przepraszamy za niedogodności, ale licencja na korzystanie z
                  tej usługi wygasła, co spowodowało tymczasowe ograniczenie
                  dostępu.
                  <br />
                  <br /> Prosimy o kontakt z administratorem strony w celu
                  uzyskania dalszych informacji.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='App'>
      {isLoggedIn && user && (
        <header>
          <div className='header-left'>Aktualna data: {currentDate}</div>
          <div className='header-right'>
            <div className='header-logged'>
              <div className='header-user'>
                Zalogowano jako: <span>{user.name}</span>{' '}
              </div>
              <button className='logout-button' onClick={handleLogout}>
                Wyloguj się
              </button>
            </div>
          </div>
        </header>
      )}
      {!isLoggedIn ? (
        <LoginForm onLogin={handleLogin} />
      ) : (
        <div className='container'>
          <div className='box'>
            {userManagementVisible ? (
              <UserManagement onBack={handleBackToCompanyList} />
            ) : (
              <CompanyList
                user={user}
                onShowUserManagement={handleShowUserManagement}
              />
            )}
          </div>
        </div>
      )}
      <Footer />
    </div>
  )
}

export default App
